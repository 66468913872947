export const REQUIRED_MESSAGE = 'Required';
// eslint-disable-next-line
export const AMOUNT_TYPE_MESSAGE = 'Amount must be a number. E.g., $50.00';
// eslint-disable-next-line
export const MIN_MESSAGE = 'Must be at least ${min} characters long';
// eslint-disable-next-line
export const MAX_MESSAGE = 'Must be no more than ${max} characters long';
// eslint-disable-next-line
export const MIN_AMOUNT_MESSAGE = 'Must be at least $${min}';
// eslint-disable-next-line
export const MAX_AMOUNT_MESSAGE = 'Must be no more than $${max}'
// eslint-disable-next-line
export const REGEXP_NAME_MESSAGE = "Supported characters are letters, digits, period (.), hyphen (-), and apostrophe (')";
// eslint-disable-next-line
export const REGEXP_FULL_NAME_MESSAGE = "Name must include both a first and last name, separated by a space";
// eslint-disable-next-line
export const REGEXP_ACCOUNT_ID_MESSAGE = "Supported characters are letters and digits";